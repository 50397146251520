<template>
  <v-form v-model="formValid" ref="form" lazy-validation @submit.prevent="submitHandler">
    <v-card>
      <v-card-title>
        <v-textarea
          :rules="[...$rules.required, ...$rules.maxLength(500)]"
          label="Nowy tekst promocyjny"
          class="mt-6"
          outlined
          dense
          no-resize
          v-model.trim="promotionText"
        ></v-textarea>
      </v-card-title>
      <v-data-table item-key="sku" :headers="headers" :items="products" :search="search">
        <template v-slot:top>
          <div class="d-flex align-center pa-4">
            <PromoTextsUploadModal @fileUploaded="uploadProducts" />
            <PromoTextsAddProductModal @productAdded="addProduct" />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj"
              class="shrink"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </template>
        <template v-slot:item.actions="{on, item}">
          <v-icon v-on="on" size="16" @click="removeProduct(item)" class="ml-2">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="success" type="submit" :disabled="!valid" :loading="loading">
          Załaduj zmiany
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import excelParseJSON from '@/utils/excelParser'
import PromoTextsAddProductModal from '@/components/PromoTexts/PromoTextsAddProductModal'
import PromoTextsUploadModal from '@/components/PromoTexts/PromoTextsUploadModal'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import {mapActions} from 'vuex'

const PromoTextsRepository = RepositoryFactory.get('promoTexts')

export default {
  name: 'PromoTexts',
  components: {
    PromoTextsUploadModal,
    PromoTextsAddProductModal,
  },
  data() {
    return {
      search: '',
      formValid: true,
      loading: false,
      promotionText: '',
      products: [],
      headers: [
        {text: 'SKU', value: 'sku', width: '90%'},
        {text: 'Akcje', value: 'actions'},
      ],
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    async uploadProducts(file) {
      const parsedData = await excelParseJSON(file)
      const products = parsedData[Object.keys(parsedData)[0]]

      this.products = [...products, ...this.products]
    },
    removeProduct(item) {
      const {sku} = item
      this.products = this.products.filter(p => p.sku !== sku)
    },
    addProduct(item) {
      this.products = [item, ...this.products]
    },
    clear() {
      this.products = []
      this.promotionText = ''
    },
    async submitHandler() {
      const {valid, products, promotionText} = this
      if (valid) {
        const SKUList = products.map(item => +item.sku)
        const payload = new FormData()
        SKUList.forEach(item => payload.append('SKUList', item))
        payload.append('promotionText', promotionText)
        this.loading = true
        try {
          await PromoTextsRepository.setPromoText(payload)
          this.showNotification({
            message: 'NOTY_PROMO_TEXTS_ADDED',
            type: 'success',
          })
          this.clear()
          this.$refs.form.resetValidation()
          this.loading = false
        } catch (e) {
          this.loading = false
          this.showNotification({
            message: 'NOTY_ADD_PROMO_TEXT_ERROR',
            type: 'error',
          })
        }
      }
    },
  },
  computed: {
    valid() {
      return this.formValid && this.products.length > 0
    },
  },
}
</script>

<style></style>
