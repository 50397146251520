<template>
  <DefaultModal
    ref="modal"
    @formSubmitted="save"
    title="Załadowanie produktów"
    activator-name="Załaduj Excel"
  >
    <v-file-input
      :rules="$rules.required"
      label="Dodaj plik"
      outlined
      dense
      accept=".xlsx"
      v-model="file"
    />
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/Modals/DefaultModal'

export default {
  name: 'PromoTextsUploadModal',
  components: {DefaultModal},
  data() {
    return {
      file: null,
    }
  },
  methods: {
    save() {
      this.$emit('fileUploaded', this.file)
      this.$refs.modal.close()
    },
  },
}
</script>

<style scoped></style>
