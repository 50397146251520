<template>
  <v-autocomplete
    dense
    outlined
    autocomplete="off"
    label="Nazwa lub SKU"
    v-bind="$attrs"
    cache-items
    hide-details
    item-text="name"
    :item-value="returnValue"
    :items="products"
    :search-input.sync="search"
    :loading="isFetchingOptions"
    :value="value"
    @input="onInputHandler"
  ></v-autocomplete>
</template>

<script>
import RepositoryFactory from '@/repositories/RepositoryFactory'

const ProductsRepository = RepositoryFactory.get('products')
export default {
  name: 'AllProductsAutocomplete',
  props: {
    value: {
      required: false,
      type: Number,
    },
    returnValue: {
      required: false,
      type: String,
      validator(v) {
        return ['sku', 'id'].indexOf(v) !== -1
      },
      default: () => 'id',
    },
  },
  data() {
    return {
      search: '',
      products: [],
      isFetchingOptions: false,
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.fetchItems()
      }
    },
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    async fetchItems() {
      this.isFetchingOptions = true
      const {data} = await ProductsRepository.getProductList({
        SearchQuery: this.search,
        PageNumber: 1,
        OnlyActive: true,
        PageSize: 50,
      })
      this.products = data.products.map(item => {
        return {
          ...item,
          name: `${item.name} | ${item.sku}`,
        }
      })
      this.isFetchingOptions = false
    },
    onInputHandler(v) {
      this.$emit('input', v)
    },
  },
}
</script>

<style scoped></style>
