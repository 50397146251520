<template>
  <DefaultModal
    @formSubmitted="save"
    ref="modal"
    :btn-text="true"
    activator-name="Dodaj produkt"
    title="Załadowanie produktów"
  >
    <AllProductsAutocomplete :rules="$rules.required" return-value="sku" v-model="sku" />
  </DefaultModal>
</template>

<script>
import DefaultModal from '@/components/Modals/DefaultModal'
import AllProductsAutocomplete from '@/components/UI/Autocompletes/AllProductsAutocomplete'

export default {
  name: 'PromoTextsAddProductModal',
  components: {AllProductsAutocomplete, DefaultModal},
  data() {
    return {
      sku: null,
    }
  },
  methods: {
    save() {
      this.$emit('productAdded', {sku: this.sku})
      this.$refs.modal.close()
      this.sku = null
    },
  },
}
</script>

<style scoped></style>
